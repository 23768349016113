import {
  FETCH_MERCADOPAGO_CARDS,
} from "../store/types";

export const INITIAL_STATE = {
  cards: [],
  message: null
}

export const cardsreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MERCADOPAGO_CARDS:
      return {
        ...state,
        cards: action.payload
      };
    default:
      return state;
  }
};